import { COOKIE_MAP, getCookie } from 'services/cookie.service';
import { getUserAuth } from 'services/identity.service';

export const shouldUpdateTimeZone = () => {
  const { userId } = getCookie({ name: COOKIE_MAP.CLAIMS }) || {};
  const roleAssumedByPlanner = Boolean(userId);
  if (roleAssumedByPlanner) return false;

  const userAuth = getUserAuth() || {};
  if (!userAuth?.id) return false;

  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { timezone } = userAuth;
  const notHavingTimezone = !timezone;
  const differentTimezone = timezone !== browserTimeZone;
  return notHavingTimezone || differentTimezone;
};

export default shouldUpdateTimeZone;
