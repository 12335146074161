import Image from 'next/image';

import SearchInput from 'components/Common/header/Header/SearchInput';
import { staticMediaStoreBaseURL } from 'config/common';

const SearchBox = ({
  handleBack,
  setShowSearchModal,
  heading,
  searchedValue,
  searchResultPage = false
}) => (
  <div className='flex md:hidden items-center w-3/5 xxs:w-6.5/10 md:h-3/5'>
    <Image
      height={13}
      width={15}
      src={`${staticMediaStoreBaseURL}/icons/back-angle-black.svg`}
      alt='Back Icon'
      className='inline-block hafla-header-back cursor-pointer'
      onClick={handleBack}
    />
    <div
      className='flex items-center md:block w-full'
      onClick={() => {
        if (searchResultPage) {
          setShowSearchModal(true);
        }
      }}
    >
      {searchResultPage ? (
        <SearchInput {...{ heading, searchedValue }} />
      ) : (
        <div className='ms-2 text-sm md:w-full h-full overflow-hidden text-nero font-Montserrat font-medium'>
          {heading}
        </div>
      )}
    </div>
  </div>
);

export default SearchBox;
